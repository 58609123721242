import { v5 as uuid } from 'uuid'
import ls from 'localstorage-slim'
import Bowser from 'bowser'
import AppStore from '../Configurations/AppStore'
import { POSTLOGIN_BYPASS_IDS, USE_CLEVERTAP } from '~/src/Configurations/env'
import { NRI_TYPES } from '../Pages/NRiAccountSelection/NRiAccountSelection.Page'
import { Account } from '../Pages/NRiAccountSelection/Nre'

ls.config.encrypt = true

export const isAuthenticated = () => true

export const UUIDGenerator = (name: string) => {
  const nameSpace = '57e9fe74-3d60-11ed-aa61-1359468a0d29'
  return uuid(name, nameSpace)
}

export const setLocalStorage = (key: string, value: any) => {
  ls.set(key, value, { secret: 12 })
}

export const getLocalStorage = (key: string) => {
  return ls.get(key, { secret: 12 })
}

export const removeLocalStorage = (key: string) => {
  ls.remove(key)
}

const hiddenEmail = (emailAddress: string, emailAddressType: string) => {
  return (
    emailAddress?.charAt(0) +
    '*****' +
    emailAddress?.charAt(emailAddress.length - 1) +
    '@' +
    emailAddressType
  )
}

const hiddenMobileEmail = (
  mobileNumber: string,
  emailAddress: string,
  emailAddressType: string,
  pageState?: { subHeading: string }
) => {
  if (pageState !== undefined) {
    if (mobileNumber !== undefined)
      return (
        pageState.subHeading +
        ' ' +
        '+91 ' +
        (mobileNumber.slice(-4).padStart(10, '*') +
          ' ' +
          'and' +
          ' ' +
          hiddenEmail(emailAddress, emailAddressType))
      )
    else {
      return (
        pageState.subHeading + ' ' + hiddenEmail(emailAddress, emailAddressType)
      )
    }
  } else {
    return (
      hiddenEmail(emailAddress, emailAddressType) +
      ' ' +
      'and' +
      ' ' +
      '+91 ' +
      mobileNumber.slice(-4).padStart(10, '*')
    )
  }
}

export const hiddenText = (
  mobileNumber: string,
  email: string,
  pageState?: { subHeading: string }
) => {
  const emailAddress = email?.split('@')[0]
  const emailAddressType = email?.split('@')[1]
  let data = hiddenMobileEmail(
    mobileNumber,
    emailAddress,
    emailAddressType,
    pageState
  )

  /*
  for NRI customers we need to show only the email id as OTP is not sent on the mobile numbr in NRI cust case
   */

  return {
    ...pageState,
    subHeading: data
  }
}

export const handleClickShowPassword = (show: boolean) => !show

export const handleMouseDownPassword = (
  event: React.MouseEvent<HTMLButtonElement>
) => {
  return event.preventDefault()
}

export const getIntervalInMileSeconds = (mileSeconds: any, callbackFn: any) => {
  return setInterval(() => {
    callbackFn()
  }, mileSeconds)
}

export const convertToMinuteAndSeconds = function (seconds: any) {
  let minutes = Math.floor(seconds / 60)
  let extraSeconds = seconds % 60
  minutes = minutes < 10 ? '0' + minutes : (minutes as any)
  extraSeconds = extraSeconds < 10 ? '0' + extraSeconds : (extraSeconds as any)
  return minutes + ':' + extraSeconds
}

export const getSubAccountId = (
  accounts: { isPrimary: boolean; subAccountId: string }[]
) => {
  // get primary subaccount id
  const primaryAccount = accounts.find(({ isPrimary }) => isPrimary === true)
  const subAccountId = primaryAccount?.subAccountId || accounts[0].subAccountId
  return subAccountId
}

// example of how to use the function

//  const customAttributes = {
//    "Event Status": 'Pass',
//    "Age": '23',
//    "Gender": "Male"
//  }
//  triggerCleverTapEvent('Event_Name', customAttributes)
export const triggerCleverTapEvent = (
  eventName: string,
  customAttributes = {}
) => {
  const { deviceName, osType } = AppStore.getState().auth
  const systemAttributes = {
    'App Name': 'Login MS',
    'App Version': '1.0.13',
    'Device Type': 'Web',
    'Name of Device':
      deviceName ||
      Bowser.parse(window.navigator.userAgent).browser.name +
        ' ' +
        Bowser.parse(window.navigator.userAgent).browser.version ||
      'Not Fetched',
    'OS Type':
      osType ||
      Bowser.parse(window.navigator.userAgent).os.name +
        ' ' +
        Bowser.parse(window.navigator.userAgent).os.version ||
      'Not Fetched',
    'Event Time': new Date().toISOString()
  }

  const eventData = { ...systemAttributes, ...customAttributes }

  if (USE_CLEVERTAP === 'true')
    window.clevertap.event.push(eventName, eventData)
}

export const shouldByPassPostLoginCheck = (subAccountId: string) => {
  if (!POSTLOGIN_BYPASS_IDS) return false
  const POSTLOGIN_BYPASS_ID_ARRAY = (POSTLOGIN_BYPASS_IDS as string).split(',')
  return POSTLOGIN_BYPASS_ID_ARRAY?.includes(subAccountId)
}
export const getNRICustomer = (accounts: any[]): boolean => {
  const nriTypeValues = new Set(Object.values(NRI_TYPES))
  return accounts.some(account => nriTypeValues.has(account.settlementType))
}
