import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { enqueueNotistack } from '@am92/react-design-system'

// utils
import withRouter, { WithRouter } from '~/src/Lib/withRouter'

// actions
import { clearErrorAction, setErrorAction } from '../Redux/Error/Actions'

// constants
import {
  HOC_HANDLED_ERROR_CODES,
  REDUX_RESET_ERROR_CODES,
  getErrorObj
} from '../Constants/ERROR_MAP'
import { getErrorObjSelector } from '../Redux/Error/Selectors'
import ErrorDialog from '../Components/ErrorDialog'
import { To } from 'react-router'
import { resetDataAction } from '../Redux/Journey/Actions'
import { TAppDispatch, TAppStore } from '../Configurations/AppStore'

type ActionTypes = {
  setError: (errorObj: any) => any
  clearError: () => any
  resetData: (data: string[]) => any
}

export interface IErrorNotifierHandlerProps extends WithRouter {
  errorObj: any
  actions: ActionTypes
}

export default function withErrorConnect(
  mapStateToProps: (state: TAppStore) => any | null | undefined,
  mapDispatchToProps: (dispatch: TAppDispatch) => any | null | undefined
) {
  const mapStateToPropsWrapper = (state: TAppStore) => {
    const componentState = mapStateToProps && mapStateToProps(state)
    const errorObj = getErrorObjSelector(state)
    return {
      errorObj,
      ...componentState
    }
  }

  const mapDispatchToPropsWrapper = (dispatch: TAppDispatch) => {
    const componentActions = mapDispatchToProps && mapDispatchToProps(dispatch)
    return {
      actions: {
        resetData: (data: string[]) => dispatch(resetDataAction(data)),
        setError: (errorObj: any) => dispatch(setErrorAction(errorObj)),
        clearError: () => dispatch(clearErrorAction()),
        ...componentActions.actions
      }
    }
  }

  return function withErrorNotifierHandler(
    WrappedComponent: React.ElementType<any>
  ) {
    class ErrorNotifierHandler extends PureComponent<IErrorNotifierHandlerProps> {
      formRef = React.createRef<any>()

      handleError = async (res: any, meta?: any) => {
        const { actions, navigateTo } = this.props
        const { error } = res

        // temperory change //
        let { code } = error

        // if (code === 1003) {
        //   code = 2000
        // }
        // temperory change //

        const errorObj = getErrorObj(code)

        if (errorObj && errorObj.type === 'FIELD_ERROR') {
          if (this.formRef.current && this.formRef.current.setFieldError) {
            const { fieldErrors } = errorObj

            const touchedObj = Object.keys(fieldErrors).reduce(
              (acc, fieldError: any) => {
                acc[fieldError] = true
                return acc
              },
              {} as any
            )

            const updatedFieldErrors = Object.keys(fieldErrors).reduce(
              (acc, fieldError: any) => {
                if (typeof fieldErrors[fieldError] === 'function') {
                  acc[fieldError] = fieldErrors[fieldError](error)
                } else {
                  acc[fieldError] = fieldErrors[fieldError]
                }
                return acc
              },
              {} as any
            )

            await this.formRef.current.setTouched(touchedObj)
            await this.formRef.current.setErrors(updatedFieldErrors)
          }
          return
        }

        if (errorObj && errorObj.type === 'DIALOG') {
          const { dialog = {} as any, bottomStagger = {} as any } = errorObj
          const { descriptions: dialogDesc = '', title: dialogTitle = '' } =
            dialog || {}
          const {
            descriptions: bottomStaggerDesc = '',
            title: bottomStaggerTitle = ''
          } = bottomStagger || {}
          let newErrorObj = null
          if (dialog && typeof dialogDesc === 'function') {
            newErrorObj = {
              ...errorObj,
              dialog: {
                ...errorObj.dialog,
                descriptions: dialogDesc(meta)
              }
            }
          }
          if (bottomStagger && typeof bottomStaggerDesc === 'function') {
            newErrorObj = {
              ...errorObj,
              bottomStagger: {
                ...errorObj.bottomStagger,
                descriptions: bottomStaggerDesc(meta)
              }
            }
          }
          if (dialog && typeof dialogTitle === 'function') {
            newErrorObj = {
              ...errorObj,
              dialog: {
                ...errorObj.dialog,
                title: dialogTitle(meta)
              }
            }
          }
          if (bottomStagger && typeof bottomStaggerTitle === 'function') {
            newErrorObj = {
              ...errorObj,
              bottomStagger: {
                ...errorObj.bottomStagger,
                title: bottomStaggerTitle(meta)
              }
            }
          }
          if (newErrorObj) {
            actions.setError(newErrorObj)
            return
          }
          actions.setError(errorObj)
          return
        }

        if (errorObj && errorObj.type === 'NOTISTACK_NAVIGATION') {
          enqueueNotistack(
            errorObj.notification || { message: 'Something Went Wrong.' }
          )
          actions.setError(errorObj)
          if (typeof errorObj?.navigation?.pathname === 'string') {
            navigateTo(errorObj?.navigation as To)
          } else {
            navigateTo(errorObj?.navigation?.pathname as number)
          }
          return
        }

        if (errorObj && errorObj.type === 'NOTISTACK') {
          enqueueNotistack(
            errorObj.notification || { message: 'Something Went Wrong.' }
          )
          actions.setError(errorObj)
          return
        }

        if (errorObj && errorObj.type === 'NAVIGATION') {
          actions.setError(errorObj)
          if (typeof errorObj?.navigation?.pathname === 'string') {
            navigateTo(errorObj?.navigation as To)
          } else {
            navigateTo(errorObj?.navigation?.pathname as number)
          }
          return
        }

        enqueueNotistack({
          message: 'Something Went Wrong.'
        })
      }

      handleExternalLink = (data: { url: string }) => {
        if (data && data.url) {
          window.open(data.url)
        }
      }

      handleLink = (data: { pathname: string }) => {
        if (data && data.pathname) {
          const { navigateTo } = this.props
          navigateTo(data.pathname)
        }
      }

      handleCloseDialog = async () => {
        const { actions, errorObj } = this.props
        const { errorCode } = errorObj

        if (REDUX_RESET_ERROR_CODES.includes(errorCode)) {
          const { dialog = {} } = errorObj
          const { primaryButtonClickActions = [] } = dialog
          for (const primaryButtonClickAction of primaryButtonClickActions) {
            if (primaryButtonClickAction.type === 'RESET_REDUX_VALUES') {
              this.handleReduxReset(primaryButtonClickActions[0].data)
              break
            }
          }
        }
        await actions.clearError()
      }

      handleRestForm = async () => {
        if (
          this.formRef.current &&
          this.formRef.current.resetForm &&
          typeof this.formRef.current.resetForm === 'function'
        ) {
          await this.formRef.current.resetForm()
        }
      }

      handleReduxReset = async (data: { redux_fields: string[] }) => {
        const { actions } = this.props
        await actions.resetData(data.redux_fields)
      }

      checkIfApiCallError = (actions: any[]): boolean => {
        const isApiCall = actions.find(action => action.type === 'API_CALL')
        return !!isApiCall
      }

      handlePrimaryButtonClick = () => {
        const { errorObj = {} } = this.props
        const { dialog = {} } = errorObj
        const { primaryButtonClickActions = [] } = dialog
        const checkIfApiCallError = this.checkIfApiCallError(
          primaryButtonClickActions
        )

        if (!checkIfApiCallError) {
          this.handleActions(primaryButtonClickActions)
        }
      }

      handleSecondaryButtonClick = () => {
        const { errorObj = {} } = this.props
        const { dialog = {} } = errorObj
        const { secondaryButtonClickActions = [] } = dialog
        const checkIfApiCallError = this.checkIfApiCallError(
          secondaryButtonClickActions
        )

        if (!checkIfApiCallError) {
          this.handleActions(secondaryButtonClickActions)
        }
      }

      handleActions = (actions: any[]) => {
        actions.forEach((action: any) => {
          switch (action.type) {
            case 'EXTERNAL_LINK': {
              this.handleExternalLink(action.data)
              break
            }
            case 'LINK': {
              this.handleLink(action.data)
              break
            }
            case 'CLOSE': {
              this.handleCloseDialog()
              break
            }
            case 'RESET_FORM': {
              this.handleRestForm()
              break
            }
            case 'RESET_REDUX_VALUES': {
              this.handleReduxReset(action.data)
              break
            }
          }
        })
      }

      render() {
        const { errorObj } = this.props
        const { errorCode } = errorObj
        return (
          <>
            <WrappedComponent
              {...this.props}
              formRef={this.formRef}
              handleError={this.handleError}
              handleCloseDialog={this.handleCloseDialog}
              handlePrimaryButtonClick={this.handlePrimaryButtonClick}
              handleSecondaryButtonClick={this.handleSecondaryButtonClick}
            />
            {HOC_HANDLED_ERROR_CODES.includes(errorCode) &&
              errorObj.type === 'DIALOG' && (
                <ErrorDialog
                  {...errorObj}
                  primaryButtonClick={this.handlePrimaryButtonClick}
                  secondaryButtonClick={this.handleSecondaryButtonClick}
                  onClose={this.handleCloseDialog}
                />
              )}
          </>
        )
      }
    }

    return withRouter(
      connect(
        mapStateToPropsWrapper,
        mapDispatchToPropsWrapper
      )(ErrorNotifierHandler)
    )
  }
}
