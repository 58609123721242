import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'error'

const select = (state: TAppStore) => state[SLICE_NAME]
const errorColdeSelect = (state: TAppStore) => state[SLICE_NAME].error.errorCode
// const errorCodeSelect = (state : TAppStore) => state[SLICE_NAME].isLoggedIn

export const getErrorObjSelector = createSelector(select, error => error.error)

export const getErrorCodeSelector = createSelector(
  errorColdeSelect,
  errorCode => errorCode
)
