import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'theme'

const select = (state: TAppStore) => state[SLICE_NAME]
const modeSelect = (state: TAppStore) => state[SLICE_NAME].mode

export const getThemeModeSelector = createSelector(
  modeSelect,
  (mode: 'light' | 'dark') => mode
)
