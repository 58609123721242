import {
  DsStack,
  DsDivider,
  DsBox,
  DsRemixIcon,
  DsTypography,
  DsImage,
  DsButton,
  DsDialog
} from '@am92/react-design-system'
import React from 'react'
import { To } from 'react-router'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { WithRouter } from '~/src/Lib/withRouter'
import {
  getAccounts,
  getSelecteAccount,
  getSessionId,
  getValidateAccessCode,
  isNRICustomer
} from '~/src/Redux/Journey/Selectors'
import completeSSOAction, {
  CompleteSSOData
} from '~/src/Redux/Journey/Services/SSO/completeSSO.Service'
import Nre, { Account } from './Nre'
import NriHeader from './NriHeader'
import Nro from './Nro'
import {
  updateCustomerNameAction,
  updateSelectedAccountAction
} from '~/src/Redux/Journey/Actions'
import postLoginCheckService, {
  PostLoginCheckData
} from '~/src/Redux/Journey/Services/PostLoginCheck/postLoginCheck.Service'
import {
  shouldByPassPostLoginCheck,
  triggerCleverTapEvent
} from '~/src/utils/global'
import { NRI_MOBILE_IMAGE } from '~/src/Constants/ASSEST_MAP'
import logoutService from '~/src/Redux/Auth/Services/logout.Service'
import { TAppStore, TAppDispatch } from '~/src/Configurations/AppStore'

type ActionTypes = {
  completeSSO: (param: CompleteSSOData) => Promise<any>
  updateSelectedAccount: (param: Account) => Promise<any>
  clearError: () => any
  updateCustomerName: (param: string) => any
  postLoginCheck: (param: PostLoginCheckData) => Promise<any>
  logout: () => Promise<any>
}

export const NRI_TYPES: { [key: string]: number } = {
  'NRO-PIS': 1,
  'NRO-NONPIS': 2,
  'NRE-PIS': 3,
  'NRE-NONPIS': 4
}

type SeparatedAccounts = {
  NRE: Account[]
  NRO: Account[]
}

export interface INRiAccountSelectionProps extends WithRouter {
  actions: ActionTypes
  validateAccessCode: any
  accounts: Account[]
  isNRI: boolean
  name: string
  handleError: (res: any) => void
  selectedAccount: Account
  sessionId: string
  validateAccessCodeApiResponse: any
}

type T_ACCESS_CODE_STATE = {
  open: boolean
}

class NRiAccountSelection extends React.Component<INRiAccountSelectionProps> {
  state: T_ACCESS_CODE_STATE = {
    open: false
  }

  navigateTo = (route: To) => () => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleOnSelectAccount = async (accountType: number) => {
    const { accounts, actions } = this.props
    const account: any =
      accounts.find(account => account.settlementType === accountType) || {}
    if (account) {
      actions.updateSelectedAccount(account)
    }

    const { subAccountId } = account || {}
    await this.handlePostLoginJourney(subAccountId)
  }

  handlePostLoginJourney = async (subAccountId: string) => {
    const redirectUrl = await this.handleCompleteSSO(subAccountId)
    redirectUrl && this.handlePostLoginCheck(redirectUrl, subAccountId)
  }

  handleCompleteSSO = async (subAccountId: string) => {
    const { actions, handleError, sessionId, validateAccessCodeApiResponse } =
      this.props

    const { refreshToken, name } = validateAccessCodeApiResponse

    // update user detail in redux before redirecting
    const customerName = name.split(' ')[0]
    actions.updateCustomerName(customerName)

    const completeSSORequestData: CompleteSSOData = {
      refreshToken: refreshToken.token,
      sessionId,
      metadata: {
        ...validateAccessCodeApiResponse,
        subAccountId
      },
      subAccountId
    }

    const completeSSOResponse = await actions.completeSSO(
      completeSSORequestData
    )
    if (completeSSOResponse._isCustomError) {
      handleError(completeSSOResponse)
      return
    }
    const { redirectURL } = completeSSOResponse.data
    return redirectURL
  }

  handlePostLoginCheck = async (redirectUrl: string, subAccountId: string) => {
    const { actions, validateAccessCodeApiResponse } = this.props
    const { userId, refreshToken } = validateAccessCodeApiResponse

    //NOTE - Temporary addition on request of ASL (Abhishek - GXY-9834)
    if (shouldByPassPostLoginCheck(subAccountId)) {
      window.location.href = redirectUrl // returning to initiator
      return
    }

    const postLoginCheckRequestData: PostLoginCheckData = {
      userId,
      refreshToken: refreshToken.token,
      subAccountId,
      redirectUrl: redirectUrl.split('sso')[0]
    }
    const postLoginCheckResponse = await actions.postLoginCheck(
      postLoginCheckRequestData
    )
    if (postLoginCheckResponse._isCustomError) {
      const { error } = postLoginCheckResponse
      const { code, data } = error
      const { redirectUrl } = data
      const customAttributes = {
        Event_Status: 'Fail',
        Screen_Name: 'PostLogin_Check',
        Error_Code: code
      }
      triggerCleverTapEvent('Login', customAttributes)
      window.location.href = redirectUrl
      return
    }
    const customAttributes = {
      Event_Status: 'Pass',
      Screen_Name: 'PostLogin_Check'
    }
    triggerCleverTapEvent('Login', customAttributes)
    window.location.href = redirectUrl // returning to initiator
    return
  }

  handleOnClose = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleLogout = async () => {
    const { actions } = this.props
    await actions.logout()
  }

  render() {
    const { selectedAccount, accounts, isNRI, name, navigateTo } = this.props
    const { open } = this.state

    const separateNRIAccounts = () => {
      const initialAccounts: SeparatedAccounts = { NRE: [], NRO: [] }
      return accounts?.reduce((acc, { settlementType, ...rest }) => {
        const isNRE = [3, 4].includes(settlementType)
        const isNRO = [1, 2].includes(settlementType)
        if (isNRE) {
          acc.NRE.push({ settlementType, ...rest })
        } else if (isNRO) {
          acc.NRO.push({ settlementType, ...rest })
        }
        return acc
      }, initialAccounts)
    }

    return (
      <>
        <DsStack
          sx={{
            display: 'flex',
            width: '100%',
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <DsBox
            sx={{
              width: '100%',
              display: { xs: 'flex', md: 'none' },
              gap: 'var(--ds-spacing-bitterCold)',
              alignItems: 'center'
            }}
          >
            <DsRemixIcon
              onClick={() => this.setState({ open: true })}
              className='ri-close-fill'
              fontSize='mild'
              sx={{
                cursor: 'pointer'
              }}
            />
            <DsTypography variant='headingBoldSmall'>
              Select Profile
            </DsTypography>
          </DsBox>

          <DsBox
            sx={{
              width: '-webkit-fill-available',
              backgroundColor: { xs: '#FFF2F8', md: 'unset' },
              position: 'relative',
              display: { xs: 'flex', md: 'none' }
            }}
          >
            <DsImage
              width={'-webkit-fill-available'}
              srcSet={NRI_MOBILE_IMAGE}
              style={{
                width: '-webkit-fill-available',
                display: 'flex'
              }}
            />
          </DsBox>

          <NriHeader name={name} />
          <Nre
            selectedAccount={selectedAccount}
            handleOnSelectAccount={this.handleOnSelectAccount}
            accounts={separateNRIAccounts().NRE}
          />
          <DsDivider
            orientation='horizontal'
            sx={{
              height: '1px'
            }}
          />
          <Nro
            selectedAccount={selectedAccount}
            handleOnSelectAccount={this.handleOnSelectAccount}
            accounts={separateNRIAccounts().NRO}
          />
        </DsStack>

        <DsDialog open={open} onClose={this.handleOnClose} showClose={false}>
          <DsStack
            justifyContent={'center'}
            alignItems={'center'}
            spacing={'24px'}
          >
            <DsTypography variant='bodyRegularSmall'>
              Are you sure you want to logout from this device?
            </DsTypography>
            <DsBox sx={{ display: 'flex' }}>
              <DsButton
                variant='text'
                color='secondary'
                onClick={this.handleOnClose}
              >
                No
              </DsButton>
              <DsButton
                variant='text'
                color='secondary'
                onClick={this.handleLogout}
              >
                Yes
              </DsButton>
            </DsBox>
          </DsStack>
        </DsDialog>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const validateAccessCode = getValidateAccessCode(state)
  const { name } = validateAccessCode
  const accounts = getAccounts(state)
  const isNRI = isNRICustomer(state)
  const selectedAccount = getSelecteAccount(state)
  const sessionId = getSessionId(state)
  const validateAccessCodeApiResponse = getValidateAccessCode(state)

  return {
    validateAccessCode,
    accounts,
    isNRI,
    name,
    selectedAccount,
    sessionId,
    validateAccessCodeApiResponse
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    completeSSO: (requestData: CompleteSSOData) =>
      dispatch(completeSSOAction(requestData)),
    updateSelectedAccount: (requestData: any) =>
      dispatch(updateSelectedAccountAction(requestData)),
    updateCustomerName: (name: string) =>
      dispatch(updateCustomerNameAction(name)),
    postLoginCheck: (requestData: PostLoginCheckData) =>
      dispatch(postLoginCheckService(requestData)),
    logout: () => dispatch(logoutService())
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(NRiAccountSelection)
