import * as React from 'react'
import { DsBottomSheet, DsBox, DsButtonBase, DsImage, DsLink, DsList, DsListItem, DsListItemText, DsRemixIcon, DsStack, DsTypography } from '@am92/react-design-system'

// constants
import {
  APPLE_STORE_ASSEST,
  AXIS_LOGO_BLACK,
  PLAY_STORE_ASSEST
} from '~/src/Constants/ASSEST_MAP'

export interface IWebFooterProps { }
export interface IWebFooterState {
  open: boolean
}

export default class WebFooter extends React.Component<IWebFooterProps, IWebFooterState> {

  state: IWebFooterState = {
    open: false
  }

  handleDisclaimerClick = () => {
    window.open('https://simplehai.axisdirect.in/disclaimer-home')
  }

  handleAppStore = () => {
    window.open('http://onelink.to/akvkqz')
  }


  handleClose = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { open } = this.state

    return (
      <>
        <DsBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <DsBox
            sx={{
              display: { md: 'flex', xs: 'none' },
              flexDirection: 'row',
              justifyContent: 'space-between',
              p: 'var(--ds-spacing-bitterCold)',
              alignItems: 'center',
              border: '1px solid var(--ds-colour-strokeDefault)',
              borderRadius: 'var(--ds-spacing-glacial)',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              mt: 'var(--ds-spacing-mild)'
            }}
          >
            <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
              <DsTypography variant="bodyRegularSmall">
                Download App:
              </DsTypography>
              <DsBox
                sx={{
                  ml: 'var(--ds-spacing-glacial)',
                  display: 'flex',
                  gap: 'var(--ds-spacing-bitterCold)',
                  alignItems: 'center'
                }}
              >
                <DsLink underline="none" onClick={this.handleAppStore}>
                  <DsImage
                    srcSet={APPLE_STORE_ASSEST}
                    alt=""
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                </DsLink>
                <DsLink underline="none" onClick={this.handleAppStore}>
                  <DsImage
                    srcSet={PLAY_STORE_ASSEST}
                    alt=""
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 'var(--ds-spacing-deepFreeze)'
                    }}
                  />
                </DsLink>
              </DsBox>
            </DsBox>
            <DsBox
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <DsLink underline="none" onClick={this.handleDisclaimerClick}>
                Disclaimer
              </DsLink>
            </DsBox>
          </DsBox>
          <DsStack sx={{ display: { xs: 'none', md: 'flex' } }} m={'var(--ds-spacing-glacial)'} spacing={'var(--ds-spacing-glacial)'}>
            <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <DsTypography
                sx={{
                  color: 'var(--ds-colour-typoTertiary)'
                }}
                variant="bodyBoldSmall"
              >
                Axis Securities Limited
              </DsTypography>
              <DsTypography
                sx={{
                  color: 'var(--ds-colour-typoTertiary)'
                }}
                variant="supportRegularFootnote"
              >
                Built in India with love &#x2764;&#xfe0f; | © 2024, All rights reserved
              </DsTypography>
            </DsBox>

            <DsTypography
              sx={{
                color: 'var(--ds-colour-typoTertiary)'
              }}
              variant="supportRegularFootnote"
            >
              NSE, BSE,MSEI, MCX & NCDEX - SEBI Single Reg. No. INZ000161633 | NSDL & CDSL - SEBI Depository Participant Reg. No. IN-DP-403-2019 | SEBI-Research Analyst Reg. No. INH 000000297
            </DsTypography>
          </DsStack>

          <DsBox sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', p: 'var(--ds-spacing-bitterCold)' }}>
            <DsTypography variant='supportRegularInfo'>License and Registration</DsTypography>
            <DsButtonBase onClick={() => this.setState({ open: true })}>
              <DsRemixIcon fontSize='small' className='ri-information-line' />
            </DsButtonBase>
          </DsBox>
        </DsBox>
        <DsBottomSheet
          sx={{ display: { xs: 'block', md: 'none' } }}
          open={open}
          showClose
          onClose={this.handleClose}
        >
          <DsStack spacing={'var(--ds-spacing-bitterCold)'} alignItems={'flex-start'}>
            <DsTypography variant='supportRegularFootnote'>License and Registration</DsTypography>
            <DsImage srcSet={AXIS_LOGO_BLACK} />
            <DsTypography variant="supportRegularFootnote" component="span">
              •  NSE & BSE - SEBI Registration no.: INZ000031633
            </DsTypography>
            <DsTypography variant="supportRegularFootnote" component="span">
              •  MCX - SEBI Registration no.: INZ000038238
            </DsTypography>
            <DsTypography variant="supportRegularFootnote" component="span">
              • CDSL - SEBI Registration no.: IN-DP-431-2019
            </DsTypography>
            <DsList itemType='default'>
            </DsList>
          </DsStack>
        </DsBottomSheet>
      </>
    )
  }
}
