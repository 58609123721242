import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'
import { NRI_TYPES } from '~/src/Pages/NRiAccountSelection/NRiAccountSelection.Page'
import { getNRICustomer } from '~/src/utils/global'

export const SLICE_NAME = 'journey'

const select = (state: TAppStore) => state[SLICE_NAME]
const isLoggedInSelect = (state: TAppStore) => state[SLICE_NAME].isLoggedIn
const accessTokenSelect = (state: TAppStore) => state[SLICE_NAME].accessToken
const refreshTokenSelect = (state: TAppStore) => state[SLICE_NAME].refreshToken

export const isValidated = createSelector(
  select,
  journey => journey.isValidated
)

export const getUnlockAccountUserAction = createSelector(
  select,
  journey => journey.resetPasswordAfterUnlocking
)

export const getMobileOrPan = createSelector(
  select,
  journey => journey.mobileOrPan
)

export const getEmailOrMobile = createSelector(
  select,
  journey => journey.emailOrMobile
)

export const getMaskedEmail = createSelector(
  select,
  journey => journey.maskedEmail
)

export const getMaskedMobile = createSelector(
  select,
  journey => journey.maskedMobile
)

export const getIdentifierType = createSelector(
  select,
  journey => journey.identifierType
)

export const getRefId = createSelector(select, journey => journey.refId)

export const getValidateAccessCode = createSelector(
  select,
  journey => journey.validateAccessCode
)
export const getAccounts = createSelector(
  select,
  journey => journey.validateAccessCode?.accounts
)

export const isNRICustomer = createSelector(select, journey => {
  return getNRICustomer(journey.validateAccessCode?.accounts)
})

export const getSessionId = createSelector(
  select,
  journey => journey.ssoValidationResponse.sessionId
)

export const showConsentSelector = createSelector(
  select,
  journey => journey.ssoValidationResponse?.concentNeeded
)

export const getRefreshToken = createSelector(
  select,
  journey => journey.ssoValidationResponse.refreshToken?.token
)

export const getValidateMetaData = createSelector(
  select,
  journey => journey.ssoValidationResponse.metadata
)

export const getPasswords = createSelector(select, journey => ({
  newPassword: journey.newPassword,
  confirmNewPassword: journey.confirmNewPassword
}))

export const getCurrentPassword = createSelector(
  select,
  journey => journey.password
)

export const getPan = createSelector(select, journey => journey.pan)

export const getUsername = createSelector(select, journey => journey.username)

export const getNewUsername = createSelector(
  select,
  journey => journey.newUsername
)

export const getCustomerName = createSelector(
  select,
  journey => journey.customerName
)

export const getIsAuthenticatedIndicator = createSelector(
  select,
  journey => journey.isAuthenticatedFlow
)

export const getAccessTokenSelector = createSelector(
  accessTokenSelect,
  accessToken => accessToken
)

export const getRefreshTokenSelector = createSelector(
  refreshTokenSelect,
  refreshToken => refreshToken
)

export const getForgotValidateOtpDataSelector = createSelector(
  select,
  journey => journey.forgotPasswordValidateOtp
)

export const getUnlockAccountValidateOtpDataSelector = createSelector(
  select,
  journey => journey.unlockAccountValidateOtp
)

export const getForgotUsernameValidateOtpDataSelector = createSelector(
  select,
  journey => journey.forgotUsernameValidateOtp
)

export const getCreateUsernameValidateOtpDataSelector = createSelector(
  select,
  journey => journey.createUsernameValidateOtp
)

export const getIsTermsAndConditionsAccepted = createSelector(
  select,
  journey => journey.isTermsAndConditionsAccepted
)

export const getSubAccountIdFromSelector = createSelector(select, journey => {
  const { validateAccessCode, customerName, settlementType = 0 } = journey
  const { accounts = [] } = validateAccessCode || {}

  const isNRI = accounts?.length && getNRICustomer(accounts)

  if (isNRI && customerName) {
    const account =
      (accounts?.length &&
        accounts.find(
          (account: { settlementType: number }) =>
            account.settlementType === settlementType
        )) ||
      {}
    return account?.subAccountId
  }
  if (accounts?.length) {
    const primaryAccount = accounts?.find(
      ({ isPrimary }: { isPrimary: boolean }) => isPrimary === true
    )
    const subAccountId =
      primaryAccount?.subAccountId || accounts[0].subAccountId //NOTE - confirm if this can be removed
    return subAccountId
  }
  return null
})

export const getSelecteAccount = createSelector(
  select,
  journey => journey.selectedAccount
)
