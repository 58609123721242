import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import {
  getTheme,
  DsCssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  DsNotistackProvider
} from '@am92/react-design-system'

import Loader from '~/src/Components/Loader'

import { getThemeModeSelector } from '~/src/Redux/Theme/Selectors'
import {
  getAccessTokenSelector,
  getRefreshTokenSelector
} from './Redux/Auth/Selectors'

import getAppRouter from '~/src/Configurations/getAppRouter'

import performHandshake from '~/src/Services/performHandshake'

import { PALETTE, FONT_FAMILY } from '~/src/Constants/THEME'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { ErrorBoundary } from './Components/ErrorBoundary'
import { setDeviceDetails } from './Redux/Auth/Reducer'
import Bowser from 'bowser'
import { TAppDispatch, TAppStore } from './Configurations/AppStore'

type ActionTypes = {
  setDeviceDetails: (requestData: any) => any
}

type Props = {
  actions: ActionTypes
  persisted: boolean
  themeMode?: 'light' | 'dark'
  accessToken?: string
  refreshToken?: string
}

type State = {
  hasError: boolean
  isLoading: boolean
}

const DefaultState: State = {
  hasError: false,
  isLoading: false
}

class App extends Component<Props, State> {
  state = DefaultState

  componentDidMount() {
    this.initialize()
    this.setDeviceDetails()
  }

  initialize = async () => {
    this.setState({ isLoading: true })
    try {
      await performHandshake()
      this.setState({ isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false, hasError: true })
    }
  }

  setDeviceDetails = () => {
    const { actions } = this.props
    actions.setDeviceDetails({
      deviceName:
        Bowser.parse(window.navigator.userAgent).browser.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).browser.version ||
        'Not Fetched',
      osType:
        Bowser.parse(window.navigator.userAgent).os.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).os.version || 'Not Fetched'
    })
  }

  render() {
    const {
      persisted,
      themeMode = 'dark'
      // accessToken,
      // refreshToken
    } = this.props
    const { isLoading, hasError } = this.state

    let children = <Loader />
    const AppTheme = getTheme(PALETTE, FONT_FAMILY)

    if (persisted) {
      const router = getAppRouter()
      children = <RouterProvider router={router} />
      window.localStorage.setItem('mui-mode', themeMode)

      // asHttp.context.set(CONTEXT.ACCESS_TOKEN, accessToken)
      // asHttp.context.set(CONTEXT.REFRESH_TOKEN, refreshToken)
    }

    return (
      <CssVarsProvider
        theme={AppTheme}
        defaultMode={themeMode}
        modeStorageKey='mui-mode'
      >
        <DsNotistackProvider>
          <DsCssBaseline enableColorScheme>
            <Suspense fallback={<Loader />}>
              {isLoading || (hasError && <ErrorBoundary />) || children}
            </Suspense>
          </DsCssBaseline>
        </DsNotistackProvider>
      </CssVarsProvider>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const themeMode = getThemeModeSelector(state)
  const accessToken = getAccessTokenSelector(state)
  const refreshToken = getRefreshTokenSelector(state)

  return {
    themeMode,
    accessToken,
    refreshToken
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setDeviceDetails: (requestData: any) =>
      dispatch(setDeviceDetails(requestData))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
