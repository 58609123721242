export const SSO_ERROR_ASSEST = [
  {
    src: new URL('~/public/assets/images/SsoError.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CROSS_WHITE_ASSEST = [
  {
    src: new URL('~/public/assets/images/crossWhite.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const PASSWORD_EXPIRY = [
  {
    src: new URL('~/public/assets/images/PasswordExpiry.png', import.meta.url)
      .href,
    alt: 'Password Expired',
    as: 'image/png'
  }
]

export const WHITE_BACK_ARROW_ASSEST = [
  {
    src: new URL('~/public/assets/images/WhiteBackArrow.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const POPUP_ASSEST = [
  {
    src: new URL('~/public/assets/images/PopupInfo.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SOMETHING_WENT_WRONG_ASSEST = [
  {
    src: new URL(
      '~/public/assets/images/somethingWentWrong.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const PLAY_STORE_ASSEST = [
  {
    src: new URL('~/public/assets/images/PlayStore.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ONBOARDING_ASSEST = [
  {
    src: new URL('~/public/assets/images/Onboarding.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const MONEY_GROW_ASSEST = [
  {
    src: new URL('~/public/assets/images/Container.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CONFIRM_SUCCESS_ASSEST = [
  {
    src: new URL('~/public/assets/images/ConfirmSuccess.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CHECK_VALIDATE_ASSEST = [
  {
    src: new URL('~/public/assets/images/checkValidate.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CHECK_ASSEST = [
  {
    src: new URL('~/public/assets/images/check.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const APPLE_STORE_ASSEST = [
  {
    src: new URL('~/public/assets/images/AppleStore.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const BACK_ARROW_ASSEST = [
  {
    src: new URL('~/public/assets/images/BackArrow.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const BULB_ASSEST = [
  {
    src: new URL('~/public/assets/images/Bulb.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const LIGHT_MONEY_GROW_ASSEST = [
  {
    src: new URL(
      '~/public/assets/images/Illustrationlight.png?as=avif',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~/public/assets/images/Illustrationlight.png?as=webp',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/webp'
  },
  {
    src: new URL(
      '~/public/assets/images/Illustrationlight.png',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/png'
  }
]

export const HEADER_MOBILE_ASSEST = [
  {
    src: new URL('~/public/assets/images/HeaderMb.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const HEADER_ASSEST = [
  {
    src: new URL('~/public/assets/images/Header.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const REDIRECT_ASSEST = [
  {
    src: new URL('~/public/assets/images/Redirect.svg', import.meta.url).href,
    alt: 'Redirect Image',
    as: 'image/svg+xml'
  }
]

export const Disabled_QR = [
  {
    src: new URL('~/public/assets/images/DisabledQr.svg', import.meta.url).href,
    alt: 'Redirect Image',
    as: 'image/svg+xml'
  }
]

export const QRLOGO = [
  {
    src: new URL('~/public/assets/images/QrLogo.svg', import.meta.url).href,
    alt: 'Redirect Image',
    as: 'image/svg+xml'
  }
]

export const NRI = [
  {
    src: new URL('~/public/assets/images/NRI.svg', import.meta.url).href,
    alt: 'NRI',
    as: 'image/svg'
  }
]

export const NRI_MOBILE_IMAGE = [
  {
    src: new URL('~/public/assets/images/NriMobileImage.svg', import.meta.url)
      .href,
    alt: 'NRI_MOBILE_IMAGE',
    as: 'image/png'
  }
]

export const AXIS_LOGO_BLACK = [
  {
    src: new URL('~/public/assets/images/AxisLogoBlack.svg', import.meta.url)
      .href,
    alt: 'AXIS_LOGO_BLACK',
    as: 'image/png'
  }
]
